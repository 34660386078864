'use strict';

var GCALFREETIME = {};

GCALFREETIME = {

  scroll_event : 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll',

  init : function() {
    $(function() { GCALFREETIME.ready(); });
    $(window).load(function() {
      $(document).on('click', '.copyText', function() {
        var ta = document.main.mainTextarea;
        ta.select();
        document.execCommand("copy");
        var oldContentEditable = ta.contentEditable,
            oldReadOnly = ta.readOnly,
            range = document.createRange();
        ta.contenteditable = true;
        ta.readonly = false;
        range.selectNodeContents(ta);
        var s = window.getSelection();
        s.removeAllRanges();
        s.addRange(range);
        ta.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
        ta.contentEditable = oldContentEditable;
        ta.readOnly = oldReadOnly;
        document.execCommand('copy');
        alert('コピーしました！');
        return false;
      });
      // #で始まるアンカーをクリックした場合に処理
      $('a[href^=#]').click(function() {
        var speed = 400; // ミリ秒
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top -10;
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
      });
    });
  },

  ready : function() {
    if(!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
      $('.wrap-mypage').addClass('is-PC');
    }
    $(document).on('click', '.js-dropNext', function() {
      $(this).next('div').slideToggle();
      $(this).toggleClass('is-show');
      return false;
    });
    if($('.js-emptyList').length && $('.js-scheduleList').length) {
      GCALFREETIME.get_calendar();
    }

    var $start = $('select.js-val_timeline_start');
    var $end = $('select.js-val_timeline_end');
    if($start.length && $end.length) {
      $start.on('change', function() {
        GCALFREETIME.setting_val_timeline_check($start, $end);
      });
      $end.on('change', function() {
        GCALFREETIME.setting_val_timeline_check($start, $end);
      });
    }

    $(document).on('click','.js-checkbox-removeattr', function() {
      var $wrap = $(this).parent('.js-checkbox');
      $wrap.find('input').prop('checked', false);
      return false;
    });
  },

  get_calendar : function() {
    var $modal = $('.js-modal');
    var $modal_text = $modal.find('.modal-inner');
    var text = '<img src="./view/assets/img/loading.gif" class="loading" />... ';
    var changeText1 = null;
    var changeText2 = null;

    $.ajax({
        url: '/',
        type: 'get',
        dataType: 'json',
        data: {
          api: 1,
        },
        beforeSend: function(){
          GCALFREETIME.no_scroll();
          $modal.show();
          $modal_text.html(text+'設定情報を取得しています');
          changeText1 = setTimeout(function() {
            $modal_text.html(text+'スケジュールを取得しています');
          },1000);
          changeText2 = setTimeout(function() {
            $modal_text.html(text+'空き時間を計算しています');
          },2000);
        },
        success: function(data){
          if(changeText1) clearTimeout(changeText1);
          if(changeText2) clearTimeout(changeText2);
          GCALFREETIME.rendar(data);
          $modal.fadeOut(function() {
            GCALFREETIME.return_scroll();
          });
        }
    })
    .fail(function () {
        if(changeText1) clearTimeout(changeText1);
        if(changeText2) clearTimeout(changeText2);
        $modal_text.html('<span class="error">空き時間の取得に失敗しました</span>');
    });
  },

  rendar : function(data) {
    var text_empty_obj = '該当カレンダーには期間中予定はありません。';
    // console.log(data);
    var empty_list = data.empty;
    if($.isEmptyObject(empty_list)) {
      $('.js-emptyList').text(text_empty_obj);
    } else {
      Object.keys(empty_list).forEach(function(key) {
        var val = this[key];
        $('.js-emptyList').append(val+"\n");
      }, empty_list);
    }

    // console.log(schedule_list);
    var schedule_list = data.schedule;
    if($.isEmptyObject(schedule_list)) {
      $('.js-scheduleList').html('<p class="smaller">'+text_empty_obj+'</p>');
    } else {
      var html = '';
      Object.keys(schedule_list).forEach(function(key) {
        var val = this[key];
        // console.log('・'+val.today);
        html += '<div>';
        html += '  <a href="" class="js-dropNext">'+val.today+'</a>';
        html += '  <div class="js-dropNext_body">';
        Object.keys(val.event).forEach(function(key) {
          var val = this[key];
          // console.log(val);
          html += val+'<br />';
        }, val.event);
        html += '</div></div>';
      }, schedule_list);
      $('.js-scheduleList').append(html);
    }
    if(!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
      $('.js-dropNext').each(function() {
        $(this).next('div').slideToggle();
        $(this).toggleClass('is-show');
      });
    }
  },

  no_scroll : function() {
    // $(document).on(GCALFREETIME.scroll_event,function(e){e.preventDefault();});
    // $(document).on('touchmove.noScroll', function(e) {e.preventDefault();});
    $('body').css({
      'position': 'fixed',
      'width': '100%'
    });

  },

  return_scroll: function() {
    // $(document).off(GCALFREETIME.scroll_event);
    // $(document).off('.noScroll');
    $('body').css({
      'position': 'static',
    });

  },


  setting_val_timeline_check: function($start,$end) {
    var $s = parseFloat($start.find('option:selected').attr('data-timeVal'));
    var $e = parseFloat($end.find('option:selected').attr('data-timeVal'));
    if($s>=$e) {
      $start.addClass('error');
      $end.addClass('error');
      $('.js-errortext').show();
      $('.js-submit').attr('disabled','disabled');
    } else {
      $start.removeClass('error');
      $end.removeClass('error');
      $('.js-errortext').hide();
      $('.js-submit').removeAttr('disabled');

    }

  },


};

GCALFREETIME.init();
